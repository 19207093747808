<template>
  <a-card title="详情">
    <template #extra><a @click="() => history.go(-1)">返回</a></template>
    <a-descriptions
      bordered
    >
      <a-descriptions-item label="歌曲名称">{{ formState.name }}</a-descriptions-item>
      <a-descriptions-item label="歌曲识别码">{{ formState.song_code }}</a-descriptions-item>
      <a-descriptions-item label="歌曲简拼">{{ formState.py }}</a-descriptions-item>
      <a-descriptions-item label="所属教材">{{ formState.book?.name || '--' }}</a-descriptions-item>
      <a-descriptions-item label="教材出版社">{{ formState.book?.press_version?.name || '--' }}</a-descriptions-item>
      <a-descriptions-item label="所属章节">{{ formState.section?.name || '--' }}</a-descriptions-item>
      <a-descriptions-item label="标签">
        <span v-if="!formState.tags.length">--</span>
        <div v-if="formState.tags.length">
          <a-tag :key="item.tag" v-for="item in formState.tags">{{ item.tag }}</a-tag>
        </div>
      </a-descriptions-item>
      <a-descriptions-item label="歌曲图片列表" :span="3">
        <a-image-preview-group>
          <span
            v-for="item in formState.picture_url"
            style="margin-right: 12px"
            :key="item"
          >
            <a-image
              :width="200"
              :height="200"
              :src="item"
              :fallback="config.fallbackImg"
            />
          </span>
        </a-image-preview-group>
      </a-descriptions-item>
    </a-descriptions>
    <a-card title="MP3配置">
      <upload-song-config
        :list="formState.mp3_file_config"
      />
    </a-card>
  </a-card>
</template>

<script>
import { useRoute } from 'vue-router'
import { ref, onBeforeMount } from 'vue'
import { getOfficialEnjoySongInfo, getPersonalEnjoySongInfo } from '@/services/songs'
import config from '@/config'
import { songType, songSheetType } from '@/utils/dataMap/song'
import UploadSongConfig from './enjoy-song-form/UploadSongConfig'

export default {
  name: 'Detail',
  components: {
    UploadSongConfig
  },
  setup () {
    const route = useRoute()
    const formState = ref({
      tags: []
    })
    onBeforeMount(async () => {
      const getInfo = route.params.type === '1' ? getOfficialEnjoySongInfo : getPersonalEnjoySongInfo
      formState.value = await getInfo(route.params.id)
    })

    return {
      history,
      formState,
      config,
      songType,
      songSheetType
    }
  }
}
</script>

<style scoped>

</style>
